// @flow

// Vendors
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import routes from './config/routes';

// Global Styles
import './styles/index.scss';

// Components
import { Page, Loader, Nav, Footer } from './components';

const App = () => (
  <Router basename={process.env.PUBLIC_URL}>
    <Nav />
    <Routes>
      {routes.map(({ path, exact, ...routeProps }) => (
        <Route key={path} exact={exact} path={path} element={
          <Suspense fallback={<Loader />}>
            <Page {...routeProps} />
          </Suspense>
        } />
      ))}
    </Routes>
    <Footer />
  </Router>
);

export default App;
