// @flow

// Vendors
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { bodyScroll } from '../../util/bodyScroll';

// Styles
import './modal.scss';

// Types
type Props = {
  handleClose: Function,
  show: boolean,
  title?: string,
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full',
  children: string,
  className?: string
};

const Modal = ({
  handleClose,
  show,
  title,
  size = 'lg',
  children,
  className
}: Props) => {
  const modalRoot = document.getElementById('modal-root');
  const classNames = classnames(
    'modal',
    {
      [`modal--${size}`]: size,
      [`modal--visible`]: show
    },
    className
  );

  useEffect(() => {
    if (show) bodyScroll.disable();
    else bodyScroll.enable();
  }, [show]);

  return ReactDOM.createPortal(
    <>
      <div className={classNames} onClick={handleClose}>
        <div
          className="modal__dialog"
          role="document"
          onClick={(event) => event.stopPropagation()}
        >
          <div className="modal__content">
            <button
              type="button"
              className="modal__close"
              onClick={handleClose}
              aria-label="Close"
            />

            {title && (
              <header className="modal__header">
                <h2 className="modal__title">{title}</h2>
              </header>
            )}

            <section className="modal__body">{show && children}</section>
          </div>
        </div>
      </div>
    </>,
    modalRoot
  );
};

export default Modal;
